import { gql } from "@apollo/client";

export const LOG_IN_MERCHANT = gql`
  mutation MerchantLogin($email: String!, $password: String!) {
    merchantLogin(email: $email, password: $password)
  }
`;

export const GET_USER = gql`
  query GetMerchantQuery {
    getMerchantQuery {
      _id
      name
      apiKey
      email_id
      role
      phone_number
      user
      trustee_id
      trustee_logo
      school_id
    }
  }
`;

export const GET_SETTLEMENT_REPORTS = gql`
  query GetMerchantSettlementReports {
    getMerchantSettlementReports {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      clientId
    }
  }
`;

export const GET_TRANSACTION_REPORT = gql`
  query GetMerchantTransactionReport(
    $startDate: String
    $endDate: String
    $status: String
    $page: String
    $limit: String
    $isQRCode: Boolean
    $isCustomSearch: Boolean
    $searchFilter: String
    $searchParams: String
    $payment_modes: [String!]
  ) {
    getMerchantTransactionReport(
      startDate: $startDate
      endDate: $endDate
      status: $status
      page: $page
      limit: $limit
      isQRCode: $isQRCode
      isCustomSearch: $isCustomSearch
      searchFilter: $searchFilter
      searchParams: $searchParams
      payment_modes: $payment_modes
    ) {
      total_pages
      current_page
      transactionReport {
        collect_id
        updatedAt
        createdAt
        order_amount
        transaction_amount
        payment_method
        school_name
        school_id
        status
        student_id
        student_name
        student_email
        student_phone
        student_receipt
        bank_reference
        remarks
        details
        isAutoRefund
        isQRPayment
        commission
        custom_order_id
        payment_time
        vendors_info {
          vendor_id
          percentage
          amount
          name
        }
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetMerchantPass($email: String!, $password: String!) {
    resetMerchantPass(email: $email, password: $password) {
      msg
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query VerifyMerchantResetPassToken($token: String!) {
    verifyMerchantResetPassToken(token: $token) {
      active
    }
  }
`;

export const RESET_MAILS = gql`
  mutation SendResetPassLinkMerchant($email: String!) {
    sendResetPassLinkMerchant(email: $email) {
      active
    }
  }
`;

export const VALIDATE_LOGIN_OTP = gql`
  mutation ValidateMerchantLoginOtp($otp: String!, $email: String!) {
    validateMerchantLoginOtp(otp: $otp, email: $email)
  }
`;

export const VERIFY_PASSWORD_OTP = gql`
  mutation VerifyPasswordOtp($otp: String!, $password: String!) {
    verifyMerchantPasswordOtp(otp: $otp, password: $password)
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMember(
    $name: String!
    $email: String!
    $phone_number: String!
    $access: String!
    $password: String!
  ) {
    createMerchantMember(
      name: $name
      email: $email
      phone_number: $phone_number
      access: $access
      password: $password
    )
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($user_id: String!) {
    deleteMerchantMember(user_id: $user_id)
  }
`;

export const GET_ALL_MEMBERS = gql`
  query GetAllMembers {
    getAllMerchantMembers {
      _id
      merchant_id
      name
      email
      phone_number
      access
    }
  }
`;

export const UPDATE_MEMBER_DETAIL = gql`
  mutation UpdateMemberDetails(
    $name: String!
    $user_id: String!
    $email: String!
    $phone_number: String!
  ) {
    updateMerchantMemberDetails(
      name: $name
      user_id: $user_id
      email: $email
      phone_number: $phone_number
    )
  }
`;

export const UPDATE_ACCESS_LEVEL = gql`
  mutation UpdateAccessLevel($user_id: String!, $access: String!) {
    updateMerchantAccessLevel(user_id: $user_id, access: $access)
  }
`;

export const SEND_CHANGE_PASS_OTP = gql`
  mutation SendOtp {
    sendMerchantResetPassOtp
  }
`;

export const UPDATE_MERCHANT_MAIL = gql`
  mutation UpdateMerchantMail($email: String!, $otp: String!) {
    updateMerchantMail(email: $email, otp: $otp)
  }
`;

export const SEND_EDIT_OTP = gql`
  mutation SendOtp {
    sendMerchantEditOtp
  }
`;

export const UPDATE_MERCHANT_PHONE_NUMBER = gql`
  mutation updateMerchantPhoneNumber($phone_number: String!, $otp: String!) {
    updateMerchantPhoneNumber(phone_number: $phone_number, otp: $otp)
  }
`;

export const GET_REFUND = gql`
  query GetOrderRefund($order_id: String!) {
    merchantOrderRefunds(order_id: $order_id) {
      created_at
      order_id
      processed_at
      refund_amount
      refund_arn
      refund_currency
      refund_id
      refund_note
      refund_speed
      refund_status
      refund_type
    }
  }
`;

export const ADD_REMARK = gql`
  mutation AddRemarks($collect_id: String!, $remark: String!) {
    addMerchantRemarks(collect_id: $collect_id, remark: $remark) {
      collect_id
      remarks
    }
  }
`;
export const DELETE_REMARK = gql`
  mutation DeleteRemark($collect_id: String!) {
    deleteMerchantRemark(collect_id: $collect_id)
  }
`;

export const GET_TRANSACTION_UTR = gql`
  query TransactionUtr($date: String!, $school_id: String!) {
    merchantTransactionUtr(date: $date, school_id: $school_id) {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      clientId
      trustee
      schoolId
    }
  }
`;

export const GET_ALL_REFUNDS = gql`
  query MerchantRefunds {
    merchantRefunds {
      order_id
      refund_amount
      refund_status
      refund_id
      bank_reference
      created_at
      order_amount
      payment_mode
      pg_refund_id
      processed_at
      refund_arn
      refund_currency
      refund_note
      refund_speed
      refund_type
      school_id
      service_charge
      service_tax
      trustee_id
    }
  }
`;

export const INITIATE_REFUND = gql`
  mutation InitiateRefundRequest(
    $order_id: String!
    $refund_amount: Float!
    $transaction_amount: Float!
    $order_amount: Float!
  ) {
    initiateRefundRequest(
      order_id: $order_id
      refund_amount: $refund_amount
      order_amount: $order_amount
      transaction_amount: $transaction_amount
    )
  }
`;

export const DELETE_REFUND = gql`
  mutation DeleteRefundRequest($refund_id: String!) {
    deleteRefundRequest(refund_id: $refund_id) {
      trustee_id
      school_id
      order_id

      status
      refund_amount
      order_amount
      transaction_amount
      commission_amount
      reason
    }
  }
`;

export const GET_REFUND_STATUS = gql`
  query GetRefundRequestMerchant($order_id: String!) {
    getRefundRequestMerchant(order_id: $order_id) {
      _id
      trustee_id
      createdAt
      updatedAt
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
      custom_id
      split_refund_details {
        vendor_id
        amount
      }
    }
  }
`;

export const GET_REFUND_REQUESTS = gql`
  query GetRefundRequests {
    getRefundRequests {
      _id
      trustee_id
      createdAt
      updatedAt
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
    }
  }
`;

export const GET_VENDORS = gql`
  query GetMerchantVendor($page: Int!, $limit: Int!) {
    getMerchantVendor(page: $page, limit: $limit) {
      totalPages
      currentPage
      vendors {
        _id
        trustee_id
        school_id
        name
        email
        phone
        createdAt
        updatedAt
        status
        bank_details {
          account_holder
          account_number
          ifsc
        }
        kyc_info {
          account_type
          business_type
          uidai
          gst
          cin
          pan
          chequeUrl
          passport_number
        }
      }
    }
  }
`;

export const GET_VENDOR_TRANSACTION = gql`
  query GetMerchantVendorTransaction($page: Int!, $limit: Int!) {
    getMerchantVendorTransaction(page: $page, limit: $limit) {
      vendorsTransaction {
        _id
        collect_id
        custom_id
        name
        school_id
        status
        amount
        createdAt
        updatedAt
      }
      totalPages
      limit
      page
    }
  }
`;

export const INITIATE_SPLIT_REFUND = gql`
  mutation InitiateSplitRefund(
    $order_id: String!
    $refund_amount: Float!
    $order_amount: Float!
    $transaction_amount: Float!
    $reason: String!
    $split_refund_details: [SplitRefundDetails!]!
  ) {
    initiateSplitRefund(
      order_id: $order_id
      refund_amount: $refund_amount
      order_amount: $order_amount
      transaction_amount: $transaction_amount
      reason: $reason
      split_refund_details: $split_refund_details
    )
  }
`;
