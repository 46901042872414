/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useRef, useState } from "react";
import { dashboardContext } from "../Dashboard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { _Table } from "../../../components/Table";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_REMARK,
  DELETE_REFUND,
  DELETE_REMARK,
  GET_ALL_REFUNDS,
  GET_REFUND,
  GET_REFUND_STATUS,
  GET_TRANSACTION_REPORT,
  GET_TRANSACTION_UTR,
  INITIATE_REFUND,
  INITIATE_SPLIT_REFUND,
} from "../../../Qurries";
import Modal from "../../../components/Modal/Modal";
import {
  Form,
  preventNegativeValues,
  preventPasteNegative,
  Select,
} from "edviron-ui-package";
import { toast } from "react-toastify";
import ConfirmationBox from "../../../components/ConfermationBox";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import refundIcon from "../../../assets/refund.svg";
import Input from "../../../components/Input/Input";

import { AiOutlineLoading3Quarters, AiTwotoneDelete } from "react-icons/ai";
import { amountFormat } from "../../../utils/amountFormat";
import { getVendorAmount } from "../../../utils/getVendorAmount";
function PaymentSmallCard({ title, value, icon }: any) {
  return (
    <div className=" flex text-xs font-normal space-y-2 flex-col">
      <div className="flex justify-between items-center max-w-[10rem]">
        <h4 className="  text-[#767676]">{title}</h4>
        {icon && icon}
      </div>
      <h3 className=" text-[#1B163B] font-semibold capitalize">
        {value && value ? value : "NA"}
      </h3>
    </div>
  );
}

function TransactionReceipt() {
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [openDeleteRemarkModal, setOpenDeleteRemarkModal] = useState(false);
  const [openInitiateRefundModal, setOpenInitiateRefundModal] = useState(false);
  const [confirmInitiateRefundModal, setConfimInitiateRefundModal] =
    useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [refunAmount, setRefundAmount] = useState(0);
  const [remark, setRemark] = useState("");
  const [deleteRefundRequest, setDeleteRefundRequest] = useState(false);
  const [refundId, setRefundId] = useState("");

  const [remarkEdit, setRemarkEdit] = useState(false);
  const [finalAmount, setFinalAmount] = useState<any>(0);
  const { transaction } = useContext(dashboardContext);
  const [concernPerson, setConcernPerson] = useState({
    name: "Merchant",
  });

  const concern_person_options =
    transaction?.vendors_info?.length > 0
      ? ["Merchant", ...transaction?.vendors_info.map((d: any) => d?.name)]
      : ["Merchant"];

  const vendor_refund_amount = getVendorAmount({
    array: transaction?.vendors_info,
    orderAmount: transaction.orderAmount,
  });

  const {
    data,
    loading: statusLoading,
    refetch,
  } = useQuery(GET_REFUND_STATUS, {
    variables: {
      order_id: transaction?.orderID,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const refundAmountTotal = data?.getRefundRequestMerchant
    .map((d: any) => {
      if (
        (d.status === "APPROVED" ||
          d.status === "INITIATED" ||
          d.status === "PROCESSING") &&
        d.split_refund_details.length <= 0
      ) {
        return d.refund_amount;
      }
    })
    .filter((d: any) => d !== undefined)
    .reduce((acc: any, current: any) => acc + current, 0);

  // const { data: utrData } = useQuery(GET_TRANSACTION_UTR, {
  //   variables: {
  //     date: transaction?.transactionTime,
  //     school_id: transaction?.school_id,
  //   },
  // });

  useEffect(() => {
    let vendor_amount;
    const vendorId = transaction.vendors_info.find(
      (d: any) => d.name === concernPerson.name
    )?.vendor_id;

    if (!vendorId) {
      console.error("Vendor ID not found");
    } else {
      vendor_amount = data?.getRefundRequestMerchant.reduce(
        (acc: number, merchant: any) => {
          if (!merchant.split_refund_details.length) return acc;

          const matchedDetail = merchant.split_refund_details.find(
            (detail: any) => detail.vendor_id.includes(vendorId)
          );

          return matchedDetail ? matchedDetail.amount : acc;
        },
        0
      );
    }

    let finalAmount;
    if (concernPerson.name === "Merchant") {
      finalAmount =
        refundAmountTotal >= 0 &&
        transaction?.order_amount - refundAmountTotal - vendor_refund_amount;
    } else {
      finalAmount =
        getVendorAmount({
          array: [
            transaction.vendors_info.find(
              (d: any) => d.name === concernPerson.name
            ),
          ],
          orderAmount: transaction.orderAmount,
        }) - vendor_amount;
    }

    setFinalAmount(finalAmount);
  }, [concernPerson]);

  const [addRemark] = useMutation(ADD_REMARK, {
    refetchQueries: [{ query: GET_TRANSACTION_REPORT }],
  });
  const [deleteRemark] = useMutation(DELETE_REMARK, {
    refetchQueries: [{ query: GET_TRANSACTION_REPORT }],
  });
  const [initiateRefund, { loading }] = useMutation(INITIATE_REFUND);
  const [vendorRefundInitiate, { loading: vendorRefundLoading }] = useMutation(
    INITIATE_SPLIT_REFUND
  );
  const [deleteRefund] = useMutation(DELETE_REFUND);

  const handlePrint = (elem: any) => {
    const printContents: any = document.getElementById("receipt");
    html2canvas(printContents).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "pt", "a4");
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("download.pdf");
    });
  };

  useEffect(() => {
    if (openInitiateRefundModal) {
      setConfimInitiateRefundModal(false);
      setConcernPerson({ name: "Merchant" });
    }
  }, [openInitiateRefundModal]);

  if (transaction === null) {
    return <Navigate to="/payments"></Navigate>;
  }

  return (
    <div className="min-h-[80vh] rounded-lg mt-4 bg-[#F6F8FA] p-4">
      <Modal
        title="Add Remark"
        open={openRemarkModal}
        setOpen={setOpenRemarkModal}
      >
        <div>
          <textarea
            name="remark"
            id="remark"
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            className="w-full border rounded-md h-24 focus:outline-none p-4"
          ></textarea>
          <button
            disabled={!remark}
            onClick={async () => {
              const res = await addRemark({
                variables: {
                  collect_id: transaction?.orderID,
                  remark: remark,
                },
              });
              if (res.data.addMerchantRemarks) {
                toast.success(res.data.addMerchantRemarks);
                window.location.reload();
                setOpenRemarkModal(false);
              }
            }}
            className="px-4 py-1.5 rounded-lg disabled:bg-gray-200 disabled:text-edviron_black disabled:cursor-not-allowed bg-[#6687FF] text-white"
          >
            Add Remark
          </button>
        </div>
      </Modal>
      <Modal open={deleteRefundRequest} setOpen={setDeleteRefundRequest}>
        <ConfirmationBox
          setOpen={setDeleteRefundRequest}
          funtion={async () => {
            const res = await deleteRefund({
              variables: {
                refund_id: refundId,
              },
            });

            if (res?.data.deleteRefundRequest) {
              toast.success("Request deleted successfully");
              refetch();
            }
          }}
          confirmationText="Delete Refund Request"
          ButtonText="Delete"
        />
      </Modal>
      <Modal title="Edit Remark" open={remarkEdit} setOpen={setRemarkEdit}>
        <div>
          <textarea
            name="remark"
            defaultValue={transaction?.remarks}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            className="w-full border rounded-md h-24 focus:outline-none p-4"
          ></textarea>
          <button
            disabled={!remark}
            onClick={async () => {
              const res = await addRemark({
                variables: {
                  collect_id: transaction?.orderID,
                  remark: remark,
                },
              });
              if (res.data.addMerchantRemarks) {
                toast.success(res.data.addMerchantRemarks);
                window.location.reload();
                setRemarkEdit(false);
              }
            }}
            className="px-4 py-1.5 rounded-lg disabled:bg-gray-200 disabled:text-edviron_black disabled:cursor-not-allowed bg-[#6687FF] text-white"
          >
            Edit Remark
          </button>
        </div>
      </Modal>
      <Modal open={openDeleteRemarkModal} setOpen={setOpenDeleteRemarkModal}>
        <ConfirmationBox
          setOpen={setOpenDeleteRemarkModal}
          funtion={async () => {
            const res = await deleteRemark({
              variables: {
                collect_id: transaction?.orderID,
              },
            });
            if (res.data.deleteMerchantRemark) {
              toast.success(res.data.deleteMerchantRemark);
              window.location.reload();
            }
          }}
          confirmationText="Delete Remark"
          ButtonText="Delete Remark"
        />
      </Modal>
      <Modal
        open={openInitiateRefundModal}
        setOpen={setOpenInitiateRefundModal}
      >
        <Form
          onSubmit={async (data: any) => {
            try {
              if (concernPerson.name === "Merchant") {
                const res = await initiateRefund({
                  variables: {
                    order_id: transaction.orderID,
                    refund_amount: Number(refunAmount),
                    order_amount: transaction.order_amount,
                    transaction_amount: transaction.transaction_amount,
                  },
                });
                setOpenInitiateRefundModal(false);
                if (res?.data) {
                  refetch();
                  toast.success(res.data?.initiateRefundRequest);
                }
              } else {
                const res = await vendorRefundInitiate({
                  variables: {
                    order_id: transaction.orderID,
                    refund_amount: Number(refunAmount),
                    order_amount: transaction.order_amount,
                    transaction_amount: transaction.transaction_amount,
                    reason: "NA",
                    split_refund_details: {
                      vendor_id: transaction.vendors_info.find(
                        (d: any) => d.name === concernPerson.name
                      ).vendor_id,
                      amount: Number(refunAmount),
                    },
                  },
                });

                setOpenInitiateRefundModal(false);
                if (res?.data) {
                  refetch();

                  toast.success(res.data?.initiateSplitRefund);
                }
              }
            } catch (error) {}
          }}
        >
          <Select
            selected={concernPerson}
            setSelected={setConcernPerson}
            label="Select Account"
            options={concern_person_options.map((d) => ({ name: d }))}
          />
          <Input
            type="text"
            name="Order Amount"
            value={transaction?.order_amount}
            add_error={() => {}}
            required
            disabled
          />
          <Input
            type="text"
            name="Transaction Amount"
            value={transaction?.transaction_amount}
            add_error={() => {}}
            required
            disabled
          />

          {/* {confirmInitiateRefundModal === false && (
          )} */}
          <Input
            type="number"
            name="Refund Amount"
            step="any"
            add_error={() => {}}
            onChange={(d: number) => {
              setRefundAmount(d);
            }}
            placeholder="Enter Refund Amount"
            onKeyDown={preventNegativeValues}
            onPaste={preventPasteNegative}
            min={0}
            maxAmount={finalAmount}
            required
          />
          <p className="text-gray-500 sm:text-sm text-right ">
            Enter amount upto Rs.
            {finalAmount}
          </p>

          {confirmInitiateRefundModal ? (
            <div className=" space-y-3 mt-2">
              <div>
                <p className="font-bold  text-sm">
                  To confirm, type{" "}
                  <span className="capitalize">
                    {`"${concernPerson?.name.replace(
                      /\s+/g,
                      ""
                    )}${refunAmount}"`}{" "}
                  </span>
                  in the box below
                </p>
                <input
                  type="text"
                  name="confirmation-text"
                  onChange={(e) => {
                    setConfirmationText(e.target.value);
                  }}
                  className="py-2 text-sm mt-2 px-4 w-full rounded-lg border focus:outline-none"
                />
              </div>
              <button
                //type="submit"
                disabled={
                  confirmationText !==
                  concernPerson?.name.replace(/\s+/g, "") + refunAmount
                }
                className="py-2 text-sm px-16 w-full  border rounded-lg disabled:bg-gray-300 disabled:text-gray-800 bg-green-500 text-white  disabled:opacity-50"
              >
                Initiate Refund
              </button>
            </div>
          ) : (
            <div className="mt-2 mb-2 text-center">
              <button
                type="button"
                disabled={finalAmount < 1}
                onClick={() => {
                  setConfimInitiateRefundModal(true);
                }}
                className="py-2 px-16 max-w-[15rem] w-full rounded-lg disabled:bg-blue-300 bg-[#6F6AF8] text-white "
              >
                Initiate
              </button>
            </div>
          )}
        </Form>
      </Modal>

      <div className="flex justify-between items-center">
        <h3 className="flex items-center text-lg font-semibold text-[#1B163B]">
          <Link to="/payments">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className=" h-6 mr-2 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
              />
            </svg>
          </Link>
          Transaction Receipt
        </h3>
        <div className="flex gap-x-4 items-center">
          {!transaction?.remarks ? (
            <button
              className="px-4 py-1.5 text-sm  rounded-md border-2 text-[#1B163B] border-[#6687FF] "
              onClick={() => setOpenRemarkModal(true)}
            >
              Add Remark
            </button>
          ) : (
            <button
              disabled={!transaction?.remarks}
              onClick={() => {
                setOpenDeleteRemarkModal(true);
              }}
              className="px-4 py-1.5 text-sm  rounded-md border-2 text-[#1B163B] border-[#6687FF] "
            >
              Remark Delete
            </button>
          )}
          <button
            className="px-4 py-1.5 flex text-sm items-center gap-x-2 rounded-md bg-[#6687FF] text-white"
            onClick={() => handlePrint("receipt")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z"
              />
            </svg>
            Print
          </button>
          {(transaction?.transactionStatus?.toLowerCase() === "success" ||
            refundAmountTotal !== transaction?.order_amount) && (
            <button
              className="px-4 py-1.5 flex text-sm items-center gap-x-2 rounded-md bg-[#6687FF] text-white"
              onClick={() => {
                setOpenInitiateRefundModal(true);
              }}
            >
              <img src={refundIcon} alt="" className="w-6 h-6" />
              Initiate Refund
            </button>
          )}
        </div>
      </div>
      <div id="receipt" className="p-4">
        <p className=" my-4 text-base text-[#767676] font-medium">
          Payment details
        </p>
        <div className="overflow-hidden border rounded-lg">
          <div className=" bg-[#6687FF1A] border-b border-[#A9B2CF] flex items-center px-8 py-4">
            <div className="flex items-center gap-x-10">
              <p className=" text-[#1B163B] font-semibold text-sm">
                Order ID:{" "}
                <span className=" font-normal ml-2">
                  {transaction?.custom_order_id
                    ? transaction?.custom_order_id
                    : transaction?.orderID}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Order Amount:{" "}
                <span className=" font-normal ml-2">
                  {" "}
                  ₹{transaction?.orderAmount}
                </span>
              </p>
              <p className=" text-[#1B163B] font-semibold text-sm">
                Status:
                <span className="capitalize font-normal ml-2 text-green-500">
                  {transaction?.transactionStatus}
                </span>
              </p>
            </div>
            <div className="ml-auto">
              <span className="text-[#1B163B] text-xs font-normal">
                {new Date(transaction?.transactionTime).toLocaleString("hi")}
              </span>
            </div>
          </div>
          <div className="p-8 grid grid-cols-3 w-3/4 gap-6">
            <PaymentSmallCard
              title="Payment method"
              value={transaction?.paymentMode}
            />
            <PaymentSmallCard
              title="Transaction amount"
              value={`₹${transaction?.transactionAmount}`}
            />
            <PaymentSmallCard
              icon={
                transaction?.remarks && (
                  <FaRegEdit
                    onClick={() => {
                      setRemarkEdit(true);
                    }}
                    size={12}
                    className="text-[#717171] cursor-pointer"
                  />
                )
              }
              title="Remarks"
              value={transaction?.remarks}
            />
            {/* <PaymentSmallCard title="Payment Gateway" value="NA" /> */}
            <PaymentSmallCard
              title={
                transaction?.paymentMode === "Net Banking"
                  ? "Bank"
                  : transaction?.paymentMode === "UPI"
                  ? "UPI ID"
                  : transaction?.paymentMode === "Wallet"
                  ? "Provider"
                  : "Bank"
              }
              value={
                transaction?.paymentMode === "Net Banking"
                  ? transaction?.details !== undefined &&
                    JSON.parse(transaction?.details)["netbanking"][
                      "netbanking_bank_name"
                    ] === "0"
                    ? "NA"
                    : JSON.parse(transaction?.details)["netbanking"][
                        "netbanking_bank_name"
                      ]
                  : transaction?.paymentMode === "UPI"
                  ? transaction?.details !== undefined
                    ? JSON.parse(transaction?.details)["upi"]["upi_id"]
                    : "NA"
                  : transaction?.paymentMode === "Wallet"
                  ? transaction?.details !== undefined
                    ? JSON.parse(transaction?.details)["app"]["provider"]
                    : "NA"
                  : "NA"
              }
            />
            <PaymentSmallCard title="Processing fee" value="NA" />

            <PaymentSmallCard
              title="Bank reference number"
              value={transaction?.bank_reference}
            />
            <PaymentSmallCard title="Currency" value="INR" />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-8">
          <div className="col-span-1">
            <p className=" my-4 text-base text-[#767676] font-medium">
              User details
            </p>
            <div className="p-8 border rounded-lg grid grid-cols-2 gap-6">
              <PaymentSmallCard
                title="Student name"
                value={transaction?.student_name}
              />
              <PaymentSmallCard
                title="Student Enrollment ID"
                value={transaction?.student_id}
              />
              <PaymentSmallCard
                title="Email"
                value={transaction?.student_email}
              />
              <PaymentSmallCard
                title="Institute Name"
                value={transaction?.school_name}
              />
              <PaymentSmallCard
                title="Phone number"
                value={transaction?.student_phone}
              />

              <PaymentSmallCard
                title="Institute ID"
                value={transaction?.school_id}
              />
            </div>
          </div>
          <div className="col-span-1 relative">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Additional details
            </p>
            <div className="p-8 border h-[80%] rounded-lg grid grid-cols-2 gap-6">
              NA
            </div>
          </div>
        </div>

        {data?.getRefundRequestMerchant.length > 0 && (
          <div className="col-span-2">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Refund details
            </p>

            <_Table
              boxPadding=" 0"
              minHeight={" h-auto"}
              data={[
                ["Sr.No", "Refund ID", "Amount", "Status", "Date", "Action"],
                ...data?.getRefundRequestMerchant?.map(
                  (d: any, index: number) => [
                    index + 1,
                    d?._id,
                    d?.refund_amount,
                    d?.status,
                    <div className="">
                      {new Date(Number(d?.createdAt)).toLocaleString("hi")}
                    </div>,

                    <div>
                      {d?.status === "INITIATED" ? (
                        <AiTwotoneDelete
                          onClick={() => {
                            setDeleteRefundRequest(true);
                            setRefundId(d?._id);
                          }}
                          className="text-[#717171] text-xl cursor-pointer"
                        />
                      ) : (
                        "-"
                      )}
                    </div>,
                  ]
                ),
              ]}
            />
          </div>
        )}
        {/* <AiOutlineLoading3Quarters className=" animate-spin" /> */}

        {/* <div className="grid grid-cols-3 gap-x-8">
          <div className="col-span-3">
            <p className=" my-4 text-base text-[#767676] font-medium">
              Settlement details
            </p>
            <div className="p-8 border rounded-lg w-full">
              <div className=" grid grid-cols-3 w-3/4 gap-6">
                <PaymentSmallCard
                  title="Settlement date & time"
                  value={
                    utrData?.merchantTransactionUtr[0]?.settlementDate &&
                    new Date(
                      utrData?.merchantTransactionUtr[0]?.settlementDate
                    ).toLocaleString("hi")
                  }
                />
                <PaymentSmallCard
                  title="Status"
                  value={utrData?.merchantTransactionUtr[0]?.status}
                />
                <PaymentSmallCard
                  title="UTR number"
                  value={utrData?.merchantTransactionUtr[0]?.utrNumber}
                />
              </div>
            </div>
          </div>
        </div>*/}
      </div>
      {/* <div>
        {data?.merchantOrderRefunds.length ? (
          <_Table
            data={[
              [
                "Date & Time",
                "Refund Amt.",
                "CF Refund ARN",
                "Refund Speed",
                "Status",
              ],
              ...data?.merchantOrderRefunds.map((d: any) => [
                <div>{new Date(d?.created_at).toLocaleString("hi")}</div>,
                <div>{d?.refund_amount}</div>,
                <div>{d?.refund_arn}</div>,
                <div>NA</div>,
                <div>{d?.refund_status}</div>,
              ]),
            ]}
          />
        ) : (
          ""
        )}
      </div> */}

      {transaction?.vendors_info?.length > 0 ? (
        <div>
          <p className="m-4 text-base text-[#767676] font-medium">
            Vendor details
          </p>
          <_Table
            minHeight=" min-h-0"
            boxPadding=" p-0"
            copyRight={false}
            data={[
              ["Sr.No", "Vendor Name", "Vendor ID", "Amount"],
              ...transaction?.vendors_info.map((d: any, i: number) => [
                <div>{i + 1}</div>,
                <div>{d?.name}</div>,
                <div>{d?.vendor_id}</div>,
                <div>
                  {d?.amount
                    ? amountFormat(d?.amount)
                    : amountFormat(
                        (transaction.orderAmount * d?.percentage) / 100
                      )}
                </div>,
              ]),
            ]}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TransactionReceipt;
