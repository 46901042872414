export const getStartAndEndOfMonth = () => {
  const currentDate = new Date();
  const todayDate = new Date().toISOString().split("T")[0];
  // Start of the month
  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    2
  );
  const formattedStartDate = startDate.toISOString().split("T")[0];
  // End of the month
  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1
  );
  const formattedEndDate = endDate.toISOString().split("T")[0];
  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    currentDate: todayDate,
  };
};
